/* ================================= */
/*            Estilos Globales       */
/* ================================= */

.posts {
    margin-left: 20%;
    padding: 20px;
    font-family: 'Roboto Mono', monospace;
    font-size: 16px;
    max-width: 60%;
}

.posts.container h2 {
    margin: 0px;
    padding: 0px;
}

.post-entry {
    margin: 5px 0;
    padding: 5px;
    border-radius: 8px;
    transition: background-color 0.3s, transform 0.3s;
}

.post-entry p {
    font-size: 16px;
    margin-bottom: 0px;
}

.post-entry:hover {
    transform: scale(1.02);
}

.posts a {
    text-decoration: none;
}

.custom-dropdown {
    position: relative;
    display: inline-block;
    cursor: pointer;
}

.dropdown-button {
    background: none;
    border: none;
    font-size: 16px;
    cursor: pointer;
    padding: 10px 0;
    text-align: left;
    position: relative;
    transition: color 0.2s;
}

.dropdown-options {
    list-style: none;
    margin: 0;
    padding: 0;
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 10;
    border: 1px solid #ccc;
    border-radius: 5px;
    min-width: 100%;
    /* Ajusta el ancho mínimo al tamaño del botón */
    width: fit-content;
    /* Ajusta dinámicamente al contenido */
    animation: fadeIn 0.2s ease-in-out;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.dropdown-options .option {
    padding: 10px;
    cursor: pointer;
    background-color: transparent;
    transition: background-color 0.2s ease;
}

/* ================================= */
/*            Tema Oscuro (.dark)    */
/* ================================= */

.dark .posts a {
    color: var(--primary-dark-colorfont);
}

.dark .dropdown-button {
    color: var(--primary-green);
}

.dark .dropdown-options {
    background-color: var(--primary-dark-colorbg);
}

.dark .dropdown-options .option {
    color: var(--primary-dark-colorfont);
}

.dark .dropdown-options .option:hover {
    background-color: var(--primary-dark-colorbg);
    color: var(--primary-green);
}

/* ================================= */
/*           Tema Claro (.light)     */
/* ================================= */

.light .post-entry h2 {
    color: #333;
}

.light .post-entry p {
    color: #666;
}

.light .posts a {
    color: var(--primary-light-colorfont);
}

.light .dropdown-button {
    color: var(--primary-light-colorfont);
}

.light .dropdown-options {
    background-color: var(--primary-light-colorbg);
}

.light .dropdown-options .option {
    color: var(--primary-light-colorfont);
}

.light .dropdown-options .option:hover {
    background-color: var(--primary-green);
    color: #fff;
}

/* ================================= */
/*      Animación para desplegable   */
/* ================================= */

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(-10px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

/* ================================= */
/*     Media Queries (RESPONSIVE)    */
/* ================================= */

/* 
   Sugerencia: Ajuste para pantallas 
   algo más grandes (hasta 1200px).
*/
@media screen and (max-width: 1200px) {
    .posts {
        margin-left: 15%;
        max-width: 70%;
    }

    .post-entry p {
        font-size: 15px;
    }
}

/*
   Ajustes suaves para pantallas 
   medianas (hasta 800px).
   Se mantiene y respeta el original 
   añadiendo pequeños refinamientos.
*/
@media screen and (max-width: 800px) {
    .posts {
        margin-left: 10%;
        margin-top: 30px;
        padding: 0px;
        font-family: 'Roboto Mono', monospace;
        font-size: 16px;
        max-width: 90%;
    }

    .posts p {
        display: none;
    }

    .posts h3 {
        font-size: large;
        text-decoration: none;
        font-weight: 800;
    }

    .posts hr {
        display: none;
    }

    .post-entry {
        margin: 0;
        padding: 0px;
    }

    .posts ul {
        padding-left: 0;
        list-style: none;
    }

    .posts h1 {
        display: none;
    }
}

/*
   Ajustes para pantallas más pequeñas (hasta 600px):
   - Reducimos aún más margenes y max-width.
   - Ajustamos textos para evitar cortes.
*/
@media screen and (max-width: 600px) {
    .posts {
        margin-left: 5%;
        max-width: 90%;
    }

    .dropdown-button,
    .dropdown-options .option {
        font-size: 14px;
        padding: 8px;
    }
}

/*
   Ajustes extra para pantallas muy pequeñas (hasta 400px):
   - Podemos reducir tamaños de fuente 
     para que todo luzca correctamente.
*/
@media screen and (max-width: 400px) {
    .posts {
        margin-left: 5%;
        max-width: 90%;
    }

    .post-entry p {
        font-size: 14px;
    }
}