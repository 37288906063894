:root {
  --primary-light-colorfont: #121212;
  --secondary-light-colorfont: #2e2e2e;
  --primary-light-colorbg: #FFFFFF !important;
  --secondary-light-colorbg: #cdcdcd !important;

  --primary-dark-colorfont: #A9A9B3;
  --secondary-dark-colorfont: #eeeeee;
  --primary-dark-colorbg: #121212 !important;
  --secondary-dark-colorbg: #1a1a1a !important;

  --primary-green: #4df305;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(0);
  }

  to {
    opacity: 1;
    transform: translateY(-20px);
  }
}

* {
  transition: all 0.2s ease;
  font-family: 'Roboto Mono',monospace;
}

.light {
  color: var(--primary-light-colorfont);
  background: var(--primary-light-colorbg);
}

.dark {
  color: var(--primary-dark-colorfont);
  background: var(--primary-dark-colorbg);
}

.bg {
  top: 0;
  left: 0;
  z-index: -1;
  position: fixed;
  width: 100%;
  height: 100%;
}



.content {
  min-width: 100%;
  min-height: 100%;  
}

.container {
  animation: fadeInDown 1s ease-out forwards;
}

/*MODO OSCURO*/
.dark a {
  color: var(--primary-dark-colorfont);
}

.dark a:visited {
  color: var(--primary-dark-colorfont);
}

/*MODO CLARO*/
.light a {
  color: var(--primary-light-colorfont);
}

.light a:visited {
  color: var(--primary-light-colorfont);
}
