/* MarkdownReader.css */

.container-markdown {
    display: contents;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
}

.markdown {
    cursor: default;
    font-family: Inter, -apple-system, BlinkMacSystemFont, "Roboto", "Segoe UI", Helvetica, Arial, sans-serif;
    font-size: 1rem;
    line-height: 1.54;
    text-rendering: optimizeLegibility;
    margin-left: 0px;
    margin-right: 0px;
    max-width: 860px;
    margin: 0 auto;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
    /*box-sizing: border-box;*/
    border-radius: 4px;
    text-align: justify;
}

.markdown h1,
.markdown h2,
.markdown h3,
.markdown h4 {
    margin: 20px 0 20px 0;
}

table {
    box-shadow: -7px 6px 3px -4px rgba(0, 0, 0, 0.75);
}

pre {
    box-shadow: -6px 8px 3px -4px rgba(0, 0, 0, 0.45);
}


.dark pre,
.dark table {
    box-shadow: 1px 1px 10px 5px rgba(255, 255, 255, 0.05);
}

.markdown img {
    max-width: 100%;
    width: auto;
    margin: auto;
    display: block;
    border-radius: 5px;
}


.markdown pre {
    overflow-x: auto;
    max-width: 100%;
}

.post-header {
    font-size: large;
    margin-left: 0px;
    margin-right: 0px;
    max-width: 860px;
    margin: 0 auto;
    padding-left: 20px;
    padding-right: 20px;
}

.post-header .categories,
.post-header .categories span {
    opacity: 0.8;
    margin-bottom: 0px;
    padding: 0px;
    font-size: small;
    text-align: end;
    text-transform: capitalize;
    font-style: italic;
}

.post-header .categories span:hover {
    color: var(--primary-green);
}

.categories svg {
    margin-bottom: 2px;
    vertical-align: middle;
    /* Asegura que el ícono quede alineado */
}

.post-header h1 {
    margin-top: 0px;
    font-size: 50px;
    line-height: 1.54;
    margin-left: 0px;
    margin-bottom: -12px;
}

.post-header .dates {
    margin-top: 20px;
    text-align: end;
}


.post-header span {
    margin: auto;
    font-size: 20px;
    line-height: 1.54;
}


details summary {
    cursor: pointer;
    font-weight: bold;
    list-style: none;
    position: relative;
    padding: 0.5em;
}

details summary:hover{
    transform: scale(1.01);
}

details summary .summary-close {
    display: none;
}

details summary .summary-open {
    display: contents;
}

details[open] summary .summary-open {
    display: none;
}

details[open] summary .summary-close {
    display: contents;
}



details[open] summary {
    background-color: none;
}

details summary::after {
    content: '►';
    position: absolute;
    right: 10px;
    transition: transform 0.3s ease;
}

details[open] summary::after {
    transform: rotate(90deg);
}

details p,
details pre {
    transition: max-height 3s ease, opacity 3s ease;
    max-height: 0;
    opacity: 0;
    overflow: hidden;
}

details[open] p,
details[open] pre {
    max-height: 10000px;
    opacity: 1;
}

pre code.hljs {
    padding: 0px !important;
}

.code-content {
    display: block;
    white-space: pre-wrap;
    padding-left: 3em;
}

.line-number {
    display: inline-block;
    width: 1em;
    text-align: right;
    padding-right: 0.5em;
    user-select: none;
    opacity: 0.5;
    color: #888;
    vertical-align: top;
}

.code-line {
    display: inline-block;
    padding-left: 0.5em;
    vertical-align: top;
}

pre div {
    position: relative;
    margin-bottom: 10px;
}

.code-lang-name {
    display: inline-block;
    text-transform: uppercase;
    padding-left: 47px;
}

.copy-btn {
    float: right;
    cursor: pointer;
    border: none;
    padding: 1px 10px;
    border-radius: 5px;
}

.copy-button:hover {
    background-color: #0056b3;
}

@keyframes blink_hash {
    0% {
        color: transparent;
    }

    50% {
        color: var(--primary-dark-colorfont);
    }

    100% {
        color: transparent;
    }
}

.highlight-blink {
    animation: blink_hash 1s ease-in-out infinite;
}


/* Modal images */
.image-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    position: relative;
    max-width: 90%;
    max-height: 90%;
}

.modal-content img {
    width: 100%;
    height: auto;
    border-radius: 8px;
}

.close-btn {
    position: absolute;
    top: 10px;
    right: 20px;
    font-size: 30px;
    color: white;
    cursor: pointer;
}

.close-btn:hover {
    color: rgb(255, 40, 40);
}

/* Loader imgs */
.loader {
    width: 48px;
    height: 48px;
    border: 5px solid #ffffff;
    border-bottom-color: #00ff15;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
    align-content: center;
    margin-left: 48%;
    margin-right: 48%;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.mermaid {
    max-width: 100%;
    /* Limita el ancho máximo al 100% del contenedor */
    height: auto;
    /* Deja que la altura se ajuste automáticamente */
    display: block;
    /* Asegura que el SVG se comporte como un bloque */
}

.markdown blockquote {
    padding-left: 16px;
    /* Espaciado entre la línea y el texto */
    margin: 16px 0;
    /* Espaciado vertical del blockquote */
    font-style: italic;
    /* Opcional: estilo cursivo */
    background-color: none;
    /* Opcional: fondo sutil */
    transform: all 1s ease-in-out;
}

.light .markdown blockquote {
    border-left: 4px solid var(--secondary-light-colorbg);
    /* Color del borde */
    color: var(--secondary-light-colorfont);
    /* Color del texto */
}

.markdown blockquote:hover {
    cursor: default;
}

.dark .markdown blockquote {
    border-left: 4px solid var(--secondary-dark-colorfont);
    /* Color del borde */
    color: var(--primary-dark-colorfont);
    /* Color del texto */
}

.katex {
    transform: all 0.5s ease-in-out;
}

.katex:hover {
    transform: scale(1.1);
}

/* MODO CLARO CODIGO */

.light .copy-btn {
    border: solid 1px black;
    border-radius: 5px;
    background-color: white;
    color: black;
}

.light .copy-btn:hover {
    background-color: whitesmoke;
}

.light code {
    background-color: white;
}

.light pre {
    background-color: white;
    border: solid 1px black;
    position: relative;
}


/* MODO OSCURO CODIGO */

.dark pre {
    background: #282a36;
    /* Fondo oscuro */
    padding: 1em;
    color: #f8f8f2;
    /* Color de texto principal */
}

.dark pre .code-lang-name {
    font-size: small;
    font-style: italic;
}

.dark pre hr {
    display: none;
}

.dark code {
    background: #282a36;
    color: #f8f8f2;
}

.dark .hljs-string {
    color: #f1fa8c;
    /* Amarillo claro para cadenas */
}

.dark .hljs-variable {
    color: #8be9fd;
    /* Azul claro para variables */
}

.dark .hljs-number {
    color: #bd93f9;
    /* Púrpura para números */
}

.dark .hljs-literal {
    color: #ff79c6;
    /* Rosa para literales */
}

.dark .hljs-title,
.dark .function_ {
    color: #50fa7b;
    /* Verde claro para títulos y funciones */
}

.dark .hljs-keyword {
    color: #ff79c6;
    /* Rosa fuerte para palabras clave */
}

.dark .hljs-comment {
    color: #6272a4;
    /* Gris azulado para comentarios */
}

.dark .hljs-type {
    color: #8be9fd;
    /* Azul claro para tipos */
}

.dark .hljs-attribute {
    color: #f8f8f2;
    /* Blanco para atributos */
}

.dark .hljs-symbol {
    color: #ffb86c;
    /* Naranja para símbolos */
}

@media screen and (max-width: 800px) {
    .container-markdown {
        justify-content: center;
    }

    .markdown {
        text-align: justify
    }

    .post-header {
        font-size: small;
    }

    .post-header h1 {
        font-size: 30px;
    }

    pre {
        font-size: small;
    }

    .markdown h1,
    .markdown h2,
    .markdown h3,
    .markdown h4 {
        font-size: large;
        text-align: start;
    }

}