.navigation-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--primary-light);
  padding: 15px 10%;
  font-family: 'Roboto Mono', monospace;
}

table{
  width: 100%;
}

th, td, tr, th {
 border: 1px solid;
}

td, th {
  padding: 5px;
}

.path {
  text-decoration: none !important;
}

.dark pre{
  background-color: beige;
  border: 1px solid;
  padding: 10px;
  border-radius: 10px;
  color: black;
}

.light pre{
  background-color: whitesmoke;
  
  padding: 10px;
  border-radius: 10px;
  color: black;
}


.blink {
  animation: blink 1s ease-in-out 0s infinite;
  text-decoration: none !important;
}

.dark .path {
  color: var(--primary-dark-colorfont)!important;
  font-weight: 700;
}

.light .path {
  color: var(--primary-light-color)!important;
  font-weight: 700;
}


.nav-links {
  display: flex;
  gap: 20px;
}

.nav-links a {
  text-decoration: none;
  font-size: 0.9em;
  transition: color 0.9s;
  transition: transform 0.3s ease;
}


.nav-links a:hover,
.nav-links a.active {
  border-bottom: 3px solid var(--primary-green);
}

.nav-links a:hover {
  transform: scale(1.1) !important;
}

.theme-toggle {
  background: none;
  border: none;
  cursor: pointer;
}


@keyframes blink {

  0%,
  100% {
    opacity: 1;
  }

  50% {
    opacity: 0.6;
  }
}

@media (max-width: 768px) {

  .path {
    display: none;
  }


  .navigation-bar {
    flex-direction: column;
    align-items: flex-start;
    padding-bottom: 10px
  }

  .nav-links {
    flex-direction: row;
    align-items: flex-start;
    gap: 50px;
    /* Disminuir el espacio entre links para dispositivos móviles */
  }

  .nav-links a {
    font-size: 1em;
  }
}

@media (max-width: 400px) { 
  .navigation-bar {

    padding-bottom: 20px
  }

  .nav-links {
    gap: 10px;
    /* Disminuir el espacio entre links para dispositivos móviles */
  }

  .nav-links a {
    font-size: 1em;
  }

  .theme-toggle {
    display: none;
  }
}