/* Contenedor general */
.content {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    font-family: 'Open Sans', sans-serif;
    color: #333;
  }
  
  /* Encabezado principal */
  .posts.container h1 {
    font-family: 'Poppins', sans-serif;
    font-size: 2.5rem;
    color: #222;
    margin-bottom: 2rem;
  }
  
  /* Agrupación por año */
  .year-group {
    margin-bottom: 2rem;
  }
  
  .year-group-item {
    margin-bottom: 2rem;
  }
  
  .year-title {
    text-align: center;
    font-size: 2rem;
    margin-bottom: 1.5rem;
    color: #444;
  }
  
  /* Tarjeta de proyecto */
  .project-card {
    background-color: #fff;
    border-radius: 10px;
    border: 1px solid #ddd;
    margin-bottom: 1rem;
    overflow: hidden;
    transition: box-shadow 0.3s ease;
  }
  
  .project-card:hover {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  
  /* Imagen y badge de fecha */
  .project-image {
    position: relative;
  }
  
  .project-image img {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }
  
  .project-date-badge {
    position: absolute;
    bottom: 10px;
    right: 10px;
    background: rgba(0, 0, 0, 0.65);
    color: #fff;
    padding: 5px 8px;
    border-radius: 5px;
    font-size: 0.9rem;
  }
  
  /* Contenido del proyecto */
  .project-content {
    padding: 15px;
  }
  
  .project-title {
    font-family: 'Poppins', sans-serif;
    font-size: 1.5rem;
    margin: 0 0 10px;
    color: #333;
  }
  
  .project-description {
    font-size: 1rem;
    line-height: 1.5;
    color: #666;
    margin-bottom: 10px;
  }
  
  .project-categories {
    font-size: 0.85rem;
    color: #999;
  }
  
  /* Sin proyectos */
  .no-projects {
    text-align: center;
    font-size: 1.5rem;
    color: #999;
  }
  
  /* Tema oscuro */
  .dark .content {
    color: #ddd;
    background-color: #222;
  }
  
  .dark .posts.container h1 {
    color: #fff;
  }
  
  .dark .year-title {
    color: #ccc;
  }
  
  .dark .project-card {
    background-color: #333;
    border-color: #444;
  }
  
  .dark .project-title {
    color: #fff;
  }
  
  .dark .project-description {
    color: #ccc;
  }
  
  .dark .project-categories {
    color: #bbb;
  }
  