.home {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.logo {
  width: 100%;
  max-width: 200px;
  height: auto;
  border-radius: 50%;
  margin-bottom: 20px;
}

.home-content {
  max-width: 600px;
  animation: fadeInUp 2s ease-out forwards;
}

.description {
  font-size: 1.2rem;
  margin-bottom: 20px;
}

.social-links a {
  font-size: 2rem;
  text-decoration: none !important;
  color: inherit !important;
  margin: 0 10px !important;
  display: inline-block;
  transition: transform 0.3s ease;
}

.social-links a:hover {
  transform: scale(1.1) !important;
}

footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 10px;
  font-size: 0.8rem;
}


.text-change-container {
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  margin-top: -5px;
  margin-bottom: 15px;
}


@media screen and (max-width: 800px) {
  .logo {
    margin-top: -100px;
    margin-bottom: 5px;
  }

  .home-content {
    max-width: 300px;
  }

  .social-links a {
    font-size: 1rem;
  }

  .description {
    font-size: 1rem;
    margin-bottom: 10px;
  }

  .text-change-container {
    display: none;
  }
}

@media screen and (max-width: 400px) {
  .logo {
    margin-top: -100px;
    width: 60%;
    margin-bottom: 5px;
  }

  .home-content {
    max-width: 300px;
  }

  .social-links a {
    font-size: 1rem;
  }

  .description {
    font-size: 1rem;
    margin-bottom: 10px;
  }
}


