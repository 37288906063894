.hands-background {
  position: absolute;
  top: 50%;
  /* Centrar verticalmente las manos en la mitad de la página */
  left: 0;
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 999;
  /* Las manos estarán detrás del contenido */
  pointer-events: none;
  /* Hacer que las imágenes no interfieran con clics o texto */
  opacity: 0.3;
  /* Reducir la opacidad para que no distraigan mucho */
}

.hand {
  width: 20%;
  transition: filter 0.3s, opacity 0.3s;
  animation: fadeInDown 3s ease-out forwards;
}

.light .hand {
  filter: brightness(0.6) contrast(2);
}

.dark .hand {
  filter: brightness(1) contrast(1.2);
}


@media screen and (max-width: 1300px) {
  .hand {
    width: 50%;
  }
}

@media screen and (max-width: 800px) {
  .hand {
    width: 50%;
  }
  
}