.error-page{
        padding-top: 100px!important;
        position:fixed;
        width:100%;
        height:100%;
        left:0px;
        z-index:1000;
        text-align: center;
    
}

@media screen and (max-width: 800px) {
        .error-page .logo{
                width:100%;
                position: block;
                margin: auto;
                margin-top: 10px;
        }
        
.error-page h1, .error-page p{
        margin-top: 0px;
        position: block;
}

      }